.NotePageMain {
    background-color: #efefef;
    height: 100%;
    padding: 10px;
    width: 100%;
  }
  
  .NotePageMain_content {
    color: #3a3939;
    max-width: 380px;
  }
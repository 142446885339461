.NoteListNav_list {
    list-style-type: none;
    margin: 130px 0 0;
    padding: 0;
  }
  
  .NoteListNav_button-wrapper {
    margin: 0 0 0 auto;
    max-width: 180px;
  }
  
  .NoteListNav_add-folder-button {
    margin: 20px auto;
  }
  .NoteListNav_add-folder-button:hover {
    background-color: #29862e;
    color: white;
  }
  
  .NoteListNav_folder-link {
    background-color: #030f1f;
    color: #b5b3b3;
    display: block;
    font-family: var(--custom-font);
    font-size: 20px;
    margin: 0 0 8px auto;
    max-width: 180px;
    padding: 8px 16px;
    text-decoration: none;
  }
  .NoteListNav_folder-link:hover {
    color: #FFB72F;
    cursor: pointer;
  }
  .NoteListNav_folder-link.active {
    background-color: #102541;
  }
  
  .NoteListNav_num-notes {
    color: #adadad;
    display: block;
    float: right;
    font-size: 10px;
  }